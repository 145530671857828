<template>
  <dialogForm
    :option="option"
    ref="form"
    title="关联系统数据"
    btnType="text"
    btnText="点击设置"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="form"
    @opened="onOpen"
    @submit="onSubmit"
    @cancel="onCancel"
  >
  </dialogForm>
</template>

<script>
import { requireFun } from '@/utils'
import { cloneDeep } from 'lodash'

export default {
  props: {
    data: Object
  },

  created() {
    if (this.data?.customfieldId) this.$set(this.form, 'customfieldId', this.data.customfieldId)
  },

  data() {
    return {
      form: {},
      customfieldIdCopy: null,
      option: {
        menuAlign: 'left',
        searchSize: 'small',
        labelPosition: 'left',
        column: [
          {
            label: '变量选择',
            type: 'radio',
            prop: 'customfieldId',
            span: 24,
            labelWidth: '90',
            rules: [
              {
                required: true,
                message: requireFun('变量选择')
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onSubmit(data, done) {
      this.customfieldIdCopy = cloneDeep(this.form.customfieldId)
      this.$emit('success','customfieldId',this.customfieldIdCopy)
      done()
    },

    onCancel() {
      this.form.customfieldId = cloneDeep(this.customfieldIdCopy)
    },

    onOpen() {
      this.$refs.form.$refs.body.$refs.form.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped></style>
